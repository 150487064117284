/*
  This show the payment mode selection options
*/

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

const PaymentOptions = ({
  enabledPaymentGateways,
  isOnline,
  paymentMethod,
  changePaymentMethod,
  totalAmountPaid,
  isSwiftOTPSent,
  txtCash,
  txtSmartCard,
  // txtCard,
  txtWendorSwift,
  txtLps,
  txtPrasuk,
}) => {
  let isCustomPaytmMethod = false;
  if (enabledPaymentGateways.indexOf('prasuk') >= 0 && enabledPaymentGateways.indexOf('paytm') >= 0) {
    isCustomPaytmMethod = true;
  }

  const [className, setClassName] = useState('');

  const machinetype = localStorage.getItem('machineType');

  const cash = JSON.parse(localStorage.getItem('cash'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setClassName('');
    }, 275);

    return () => {
      clearTimeout(timer);
    };
  }, [className]);

  const handleClick = () => {
    setClassName('touch-animation ');
  };

  const getCssClassNames = payOption => {
    if (machinetype !== 'big') {
      if (paymentMethod === payOption) {
        return `payment-method-button payment-method-button-active new-3d-button ${className}`;
      }
      if (totalAmountPaid > 0 || (isSwiftOTPSent && paymentMethod === 'swift')) {
        return 'payment-method-button new-3d-button';
      }
    } else if (machinetype === 'big') {
      if (paymentMethod === payOption) {
        return 'btn selected-payment-method remove-background font-black';
      }
      if (totalAmountPaid > 0 || (isSwiftOTPSent && paymentMethod === 'swift')) {
        return 'btn disabled btn-payment';
      }

      return 'btn remove-background font-black btn-payment';
    }
    return 'payment-method-button new-3d-button';
  };

  return (
    <>
      {machinetype !== 'big' && (
        <div>
          {enabledPaymentGateways.indexOf('prasuk') >= 0 && isOnline && (
            <Button className={getCssClassNames('prasuk')} onClick={() => changePaymentMethod('prasuk')}>
              <img
                onTouchEnd={() => handleClick()}
                alt="Wendor"
                src="/prasuk-logo.png"
                role="button"
                className="border-radius-0 wendor-logo-payment-icon"
              />
              <br />
              <span>{txtPrasuk}</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('upi') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('upi')}
              onClick={() => changePaymentMethod('upi')}>
              <img alt="UPI" src="/assets/upi.svg" role="button" className="btn-payments border-radius-0" />
            </Button>
          )}
          {/* <Button onTouchEnd={()=>handleClick()}.Or/> */}
          {enabledPaymentGateways.indexOf('paytm') >= 0 && !isCustomPaytmMethod && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('paytm')}
              onClick={() => changePaymentMethod('paytm')}>
              <img alt="Paytm" src="/assets/upiPaytm.svg" role="button" className="btn-payments" />
            </Button>
          )}
          {/* <Button onTouchEnd={()=>handleClick()}.Or/> */}
          {enabledPaymentGateways.indexOf('paytm') >= 0 && isCustomPaytmMethod && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('paytm')}
              onClick={() => changePaymentMethod('prasuk-paytm')}>
              <img alt="Paytm" src="/assets/upiPaytm.svg" role="button" className="btn-payments" />
              <br />
            </Button>
          )}
          {/* <Button onTouchEnd={()=>handleClick()}.Or/> */}
          {enabledPaymentGateways.indexOf('rfid') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('rfid')}
              onClick={() => changePaymentMethod('rfid')}>
              <img alt="Cash" src="/images/Credit_card.svg" role="button" className="btn-payments border-radius-0" />
              <br />
              <span>{txtSmartCard}</span>
            </Button>
          )}
          {/* {enabledPaymentGateways.indexOf('link') >= 0 && isOnline && (
        <Button onTouchEnd={()=>handleClick()}
          className={getCssClassNames('razorpay_link_qr')}
          onClick={() => changePaymentMethod('razorpay_link_qr')}>
          <img
            alt="razorpay"
            src="/assets/creditcardactive.svg"
            role="button"
            className="btn-payments border-radius-0"
          />
          <span>Razorpay QR</span>
        </Button>
      )} */}
          {enabledPaymentGateways.indexOf('link') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('stripe_link_qr')}
              onClick={() => changePaymentMethod('stripe_link_qr')}>
              <img alt="Stripe" src="/assets/stripe.svg" role="button" className="btn-payments border-radius-0" />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('cash') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('cash')}
              onClick={() => changePaymentMethod('cash')}>
              <img
                alt="Cash"
                src={cash.currencyId === 3 ? '/assets/dubai-cash.png' : '/cash.png'}
                role="button"
                className={
                  cash.currencyId !== 3 ? 'btn-payments border-radius-0' : 'btn-payments-dubai-cash border-radius-0'
                }
              />
              <br />
              <span>{txtCash}</span>
            </Button>
          )}
          {/* {enabledPaymentGateways.indexOf('link') >= 0 && isOnline && (
        <Button onTouchEnd={()=>handleClick()}
          btn
          disabled={totalAmountPaid > 0 || isSwiftOTPSent}
          className={getCssClassNames('link')}
          onClick={() => changePaymentMethod('link')}>
          <img
            alt="Wendor"
            src="/assets/creditcardactive.svg"
            role="button"
            className="border-radius-0 wendor-logo-payment-icon"
          />
          <br />
          <span>{txtCard}</span>
        </Button>
      )} */}
          {enabledPaymentGateways.indexOf('swift') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('swift')}
              onClick={() => changePaymentMethod('swift')}>
              <img alt="Wendor" src="/logo.png" role="button" className="border-radius-0 wendor-logo-payment-icon" />
              <br />
              <span>{txtWendorSwift}</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('free_vend') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('free_vend')}
              onClick={() => changePaymentMethod('free_vend')}>
              {/* <img alt="Wendor" src="/logo.png" role="button" className="border-radius-0 wendor-logo-payment-icon" /> */}
              Free Vend
              <br />
              {/* <span>{txtWendorSwift}</span> */}
            </Button>
          )}
          {enabledPaymentGateways.indexOf('lps_bossard') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('lps_bossard')}
              onClick={() => changePaymentMethod('lps_bossard')}>
              <img alt="Wendor" src="/logo.png" role="button" className="border-radius-0 wendor-logo-payment-icon" />
              <br />
              <span>{txtLps}</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('sodexo') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('sodexo')}
              onClick={() => changePaymentMethod('sodexo')}>
              <img alt="Sodexo" src="/assets/sodexo.png" role="button" className="btn-payments" />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('offlinecash') >= 0 && !isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('offlinecash')}
              onClick={() => changePaymentMethod('offlinecash')}>
              <img
                alt="Cash"
                src="/assets/dubai-cash.png"
                role="button"
                className={cash.currencyId !== 2 ? 'btn-payments' : 'btn-payments-dubai-cash'}
              />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('fonepay') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('fonepay')}
              onClick={() => changePaymentMethod('fonepay')}>
              <img alt="Wendor" src="/assets/fonepay.webp" role="button" className="btn-payments-fonepay" />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('ezetap') >= 0 && isOnline && (
            <Button
              onTouchEnd={() => handleClick()}
              className={getCssClassNames('ezetap')}
              onClick={() => changePaymentMethod('ezetap')}>
              {/* <img alt="Wendor" src="/assets/ezetap.png" role="button" className="btn-payments-fonepay" /> */}
              <img alt="Wendor" src="/assets/visa-logo.png" role="button" className="btn-payments-fonepay" />
              <br />
              <span>Tap & Pay</span>
            </Button>
          )}
        </div>
      )}

      {machinetype === 'big' && (
        <div>
          {enabledPaymentGateways.indexOf('prasuk') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'prasuk', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('prasuk')}>
              <img
                alt="Wendor"
                src="/prasuk-logo.png"
                role="button"
                className="border-radius-0 wendor-logo-payment-icon"
              />
              <br />
              <span>{txtPrasuk}</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('upi') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'upi', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('upi')}>
              <img alt="UPI" src="/assets/upi.svg" role="button" className="btn-payments border-radius-0" />
            </Button>
          )}
          {/* <Button.Or/> */}
          {enabledPaymentGateways.indexOf('paytm') >= 0 && !isCustomPaytmMethod && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'paytm', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('paytm')}>
              <img alt="Paytm" src="/assets/upiPaytm.svg" role="button" className="btn-payments" />
            </Button>
          )}
          {/* <Button.Or/> */}
          {enabledPaymentGateways.indexOf('paytm') >= 0 && isCustomPaytmMethod && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'paytm', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('prasuk-paytm')}>
              <img alt="Paytm" src="/assets/upiPaytm.svg" role="button" className="btn-payments" />
              <br />
            </Button>
          )}
          {/* <Button.Or/> */}
          {enabledPaymentGateways.indexOf('rfid') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'rfid', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('rfid')}>
              <img alt="Cash" src="/images/Credit_card.svg" role="button" className="btn-payments border-radius-0" />
              <span>{txtSmartCard}</span>
            </Button>
          )}
          {/* {enabledPaymentGateways.indexOf('link') >= 0 && isOnline && (
        <Button
          className={getCssClassNames(paymentMethod, 'razorpay_link_qr', totalAmountPaid, isSwiftOTPSent)}
          onClick={() => changePaymentMethod('razorpay_link_qr')}>
          <img
            alt="razorpay"
            src="/assets/creditcardactive.svg"
            role="button"
            className="btn-payments border-radius-0"
          />
          <span>Razorpay QR</span>
        </Button>
      )} */}
          {enabledPaymentGateways.indexOf('link') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'stripe_link_qr', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('stripe_link_qr')}>
              <img alt="Stripe" src="/assets/stripe.svg" role="button" className="btn-payments border-radius-0" />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('cash') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'cash', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('cash')}>
              <img
                alt="Cash"
                src={cash.currencyId === 3 ? '/assets/dubai-cash.png' : '/cash.png'}
                role="button"
                className={
                  cash.currencyId !== 3 ? 'btn-payments border-radius-0' : 'btn-payments-dubai-cash border-radius-0'
                }
              />
              <br />
              <span>{txtCash}</span>
            </Button>
          )}
          {/* {enabledPaymentGateways.indexOf('link') >= 0 && isOnline && (
        <Button
          btn
          disabled={totalAmountPaid > 0 || isSwiftOTPSent}
          className={getCssClassNames(paymentMethod, 'link', totalAmountPaid, isSwiftOTPSent)}
          onClick={() => changePaymentMethod('link')}>
          <img
            alt="Wendor"
            src="/assets/creditcardactive.svg"
            role="button"
            className="border-radius-0 wendor-logo-payment-icon"
          />
          <br />
          <span>{txtCard}</span>
        </Button>
      )} */}
          {enabledPaymentGateways.indexOf('swift') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'swift', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('swift')}>
              <img alt="Wendor" src="/logo.png" role="button" className="border-radius-0 wendor-logo-payment-icon" />
              <br />
              <span>{txtWendorSwift}</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('lps_bossard') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'lps_bossard', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('lps_bossard')}>
              <img alt="Wendor" src="/logo.png" role="button" className="border-radius-0 wendor-logo-payment-icon" />
              <br />
              <span>{txtLps}</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('sodexo') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'sodexo', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('sodexo')}>
              <img alt="Sodexo" src="/assets/sodexo.png" role="button" className="btn-payments" />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('offlinecash') >= 0 && !isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'offlinecash', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('offlinecash')}>
              <img
                alt="Cash"
                src={cash.currencyId === 3 ? '/assets/dubai-cash.png' : '/cash.png'}
                role="button"
                className={cash.currencyId !== 3 ? 'btn-payments' : 'btn-payments-dubai-cash'}
              />
            </Button>
          )}
          {enabledPaymentGateways.indexOf('fonepay') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'fonepay', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('fonepay')}>
              <img alt="Wendor" src="/assets/fonepay.webp" role="button" className="btn-payments-fonepay" />
              <br />
              <span>FonePay</span>
            </Button>
          )}
          {enabledPaymentGateways.indexOf('ezetap') >= 0 && isOnline && (
            <Button
              className={getCssClassNames(paymentMethod, 'ezetap', totalAmountPaid, isSwiftOTPSent)}
              onClick={() => changePaymentMethod('ezetap')}>
              <img alt="Wendor" src="/assets/ezetap.png" role="button" className="btn-payments-fonepay" />
              <br />
              <span>Tap & Pay</span>
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentOptions;
