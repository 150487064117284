import React, { Fragment, memo } from 'react';
import uniqid from 'uniqid';
import Carousel from 'nuka-carousel';
import '../assets/css/RowItemCard.css';
import WithImageCardDisplay from './WithImageCardDisplay';
import ItemCardDisplayRowVersion from './ItemCardRowVersionDisplay';

class WithImageRowItemCards extends React.PureComponent {
  // export default ({ productsData, updateCart, totalItems }) => {\
  render() {
    const { productsData, index, updateCart, machineType, productDisplayPage, maxItems } = {
      ...this.props,
    };
    const settings = {
      infinite: false,
      speed: 1000,
      slidesToShow: productDisplayPage.slidesToShow || 4,
      slidesToScroll: productDisplayPage.slidesToScroll || 3,
      autoplay: false,
      arrows: !!(productsData.length > productDisplayPage.slidesToShow),
      height: productDisplayPage.showImages ? '310px' : '100px',
      className: 'slides',
    };

    const arrowColor = machineType === 'imshow' ? '#000' : '#d80000';
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    if (machineType === 'rowproductselect' || machineType === 'selectionbyproductid') {
      return (
        <div>
          <ItemCardDisplayRowVersion
            symbol={currencyDetails.symbol}
            suffix={currencyDetails.isSuffix}
            productItems={productsData}
            index={index}
            updateCart={updateCart}
            machineType={machineType}
            productDisplayPage={productDisplayPage}
          />
        </div>
      );
    }
    return (
      <div key={uniqid()} className="with-image-tray-row">
        <Carousel
          {...settings}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            if (!settings.arrows || currentSlide === 0) {
              return '';
            }
            return (
              <ion-icon style={{ color: arrowColor, fontSize: '34px' }} onClick={previousSlide} name="ios-arrow-back" />
            );
          }}
          renderBottomCenterControls={() => <div />}
          renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => {
            if (!settings.arrows || currentSlide + settings.slidesToShow >= slideCount) {
              return '';
            }
            return (
              <ion-icon style={{ color: arrowColor, fontSize: '34px' }} onClick={nextSlide} name="ios-arrow-forward" />
            );
          }}
          className="slider-control-bottomcenter paging-item">
          {machineType !== 'rowproductselect' &&
            machineType !== 'selectionbyproductid' &&
            productsData.map(productItem => {
              return (
                <Fragment key={uniqid()}>
                  <WithImageCardDisplay
                    symbol={currencyDetails.symbol}
                    suffix={currencyDetails.isSuffix}
                    productItem={productItem}
                    updateCart={updateCart}
                    machineType={machineType}
                    productDisplayPage={productDisplayPage}
                    maxItems={maxItems}
                  />
                </Fragment>
              );
            })}
        </Carousel>
      </div>
    );
  }
}

export default memo(WithImageRowItemCards);
