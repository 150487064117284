import axios from 'axios';

export const getRequestId = async ({ details }) => {
  // console.log('arguments');
  // console.log(details);
  try {
    const url = `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/generateRequestId`;
    let call;
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();

    const response = await axios({
      url,
      method: 'post',
      data: details,
      cancelToken: call.token,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const cancelPaymentLink = async ({ requestId }) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE}/api/cancelPaymentLink`;

    const response = await axios({
      url,
      method: 'post',
      data: { request_id: requestId },
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

const CHECKOUT_ENDPOINTS = {
  stripe: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/checkoutViaStripePaymentLink`,
};

export const getPaymentDetails = async details => {
  try {
    if ('payment_method' in details && details.separate_endpoint && details.payment_method in CHECKOUT_ENDPOINTS) {
      const url = `${CHECKOUT_ENDPOINTS[details.payment_method]}`;

      const response = await axios({
        url,
        method: 'post',
        data: { ...details },
      });
      return response.data;
    }

    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/checkoutRequestV2`;

    const response = await axios({
      url,
      method: 'post',
      data: details,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

const CHECK_PAYMENT_STATUS_ENDPOINTS = {
  // stripe_link_qr: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/checkStripePaymentStatus`,
  fonepay: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/fonepayCheckStatus`,
  ezetap: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/ezetapCheckStatus`,
};

export const checkPaymentStatus = async (paymentModeSelected, requestId) => {
  // Check if the payment of the latest order on th ecurrent machine is made or not
  try {
    if (paymentModeSelected in CHECK_PAYMENT_STATUS_ENDPOINTS) {
      if (paymentModeSelected === 'fonepay') {
        const response = await axios({
          method: 'post',
          url: `${CHECK_PAYMENT_STATUS_ENDPOINTS[paymentModeSelected]}`,
          data: { payment_method: paymentModeSelected, request_id: requestId },
        });

        return response.data;
      }
      // if (paymentModeSelected === 'stripe_link_qr') {

      //   const response = await axios({
      //     url: `${CHECK_PAYMENT_STATUS_ENDPOINTS[paymentModeSelected]}`,
      //     method: 'post',
      //     data: { payment_method: paymentModeSelected, request_id: requestId },
      //   });

      //   return response.data;
      // }
      if (paymentModeSelected === 'ezetap') {
        const response = await axios({
          method: 'post',
          url: `${CHECK_PAYMENT_STATUS_ENDPOINTS[paymentModeSelected]}`,
          data: { payment_method: paymentModeSelected, request_id: requestId },
        });

        return response.data;
      }
    }
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/checkStatus`,
      data: { payment_method: paymentModeSelected, request_id: requestId },
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const updateRequestStatus = (requestId, status) => {
  try {
    const response = axios({
      method: 'post',
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/updateRequestStatus`,
      data: { request_id: requestId, status },
    });
    return response.data;
  } catch (err) {
    return {
      status: false,
      data: {},
    };
  }
};

export const sendFeedback = ({ value, requestId }) => {
  try {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/feedback`,
      data: { value, bill_id: requestId },
    });

    return {
      error: false,
    };
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const sendPosData = (mSwipeData, token, requestId) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE}/api/processCardSale`;

    const response = axios({
      method: 'post',
      url,
      data: { request_id: requestId, data: JSON.parse(mSwipeData) },
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkSwiftWalletOTP = async requestData => {
  // console.log(requestData);
  // console.log('requestData -----------');
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/authoriseSwiftPayment`;

    const response = await axios({
      method: 'post',
      url,
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkFreeVendOTP = async requestData => {
  // console.log(requestData);
  // console.log('requestData -----------');
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/authoriseFreeVendPayment`;

    const response = await axios({
      method: 'post',
      url,
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkCustom3rdPartyOTPAuthOTP = async requestData => {
  // console.log(requestData);
  // console.log('requestData -----------');
  try {
    const url = `${process.env.REACT_APP_API_BASE}/api/authLPSBossard`;

    const response = await axios({
      method: 'post',
      url,
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkPrasukOTP = async requestData => {
  // console.log('requestData -----------');
  try {
    const url = `${process.env.REACT_APP_API_BASE}/api/authPrasuk`;

    const response = await axios({
      method: 'post',
      url,
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkPrasukOTPForSeparateMode = async requestData => {
  // console.log('requestData -----------');
  try {
    const url = `${process.env.REACT_APP_API_BASE}/api/authPrasukForSeparatePaymentMethods`;

    const response = await axios({
      method: 'post',
      url,
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const getPaymentDetailsFonePay = async details => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/fonepayCheckoutRequest`;

    const response = await axios({
      url,
      method: 'post',
      data: details,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkPaymentStatusFonePay = async (paymentModeSelected, requestId) => {
  // Check if the payment of the latest order on th ecurrent machine is made or not
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/fonepayCheckStatus`,
      data: { payment_method: paymentModeSelected, request_id: requestId },
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const getPaymentDetailsEzeTap = async details => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/ezetapCheckoutRequest`;

    const response = await axios({
      url,
      method: 'post',
      data: details,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const checkPaymentStatusEzetap = async (paymentModeSelected, requestId) => {
  // Check if the payment of the latest order on th ecurrent machine is made or not
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_EZETAP_PAYMENT_SERVICE_URL}/api/ezetapCheckStatus`,
      data: { payment_method: paymentModeSelected, request_id: requestId },
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const initCurrencyReturnerRefund = async (paymentModeSelected, requestId) => {
  // Check if the payment of the latest order on th ecurrent machine is made or not
  try {
    const response = await axios({
      method: 'post',
      url: `https://c0d76336-cbe5-4ae7-9d62-f54a20aae8ac.mock.pstmn.io/api/initCurrencyModuleRefund`,
      data: { payment_method: paymentModeSelected, request_id: requestId },
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};
