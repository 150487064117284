import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import '../assets/css/keyboard.css';

export default ({
  handleChange,
  details,
  showEnterCancle,
  value,
  isValid,
  placeHolderString,
  inputFeildLength,
  hideInput,
  submitButton,
  isSubmitButtonLoading,
  otpButton,
}) => {
  const [className, setClassName] = useState('');
  const [pressedKey, setPressedKey] = useState(-1);

  const machinetype = localStorage.getItem('machineType');

  useEffect(() => {
    const timer = setTimeout(() => {
      setPressedKey(-1);
      setClassName('');
    }, 155);

    return () => {
      clearTimeout(timer);
    };
  }, [className]);

  const handleClick = key => {
    setPressedKey(key);
    setClassName('touch-animation-keyboard ');
  };
  return (
    <div>
      <div className="numeric-keyboard-container">
        {details && <h3>{details}</h3>}
        <div className="keyboard-input-submit-container">
          {false && inputFeildLength <= 5 && (
            <OtpInput
              numInputs={inputFeildLength || 6}
              value={value}
              containerStyle="keyboard-container-style"
              inputStyle="keyboard-input-style"
              shouldAutoFocus
              hasErrored
              isInputNum
              separator={<span />}
            />
          )}
          {!hideInput && (
            <textarea
              className={`${isValid ? 'text-green' : 'text-red'} keyboard-output keyboard-new`}
              value={placeHolderString + value}
              readOnly
              placeholder={placeHolderString || ''}
              style={{ backgroundColor: '#fff' }}
            />
          )}
          {!isSubmitButtonLoading && submitButton}
          {otpButton}
        </div>

        <div className={machinetype !== 'big' ? 'Keyboard-keys' : 'Old-Keyboard-keys'}>
          {[1, 2, 3].map(data => (
            <button
              onTouchEnd={() => {
                if (machinetype !== 'big') handleClick(data);
              }}
              type="button"
              onClick={() => handleChange(data)}
              value={data}
              key={data}>
              <span className="keyboard-text">{data}</span>
            </button>
          ))}
        </div>
        <div className={machinetype !== 'big' ? 'Keyboard-keys' : 'Old-Keyboard-keys'}>
          {[4, 5, 6].map(data => (
            <button
              onTouchEnd={() => {
                if (machinetype !== 'big') handleClick(data);
              }}
              type="button"
              className={pressedKey === data ? className : ''}
              onClick={() => handleChange(data)}
              value={data}
              key={data}>
              <span className="keyboard-text">{data}</span>
            </button>
          ))}
        </div>
        <div className={machinetype !== 'big' ? 'Keyboard-keys' : 'Old-Keyboard-keys'}>
          {[7, 8, 9].map(data => (
            <button
              onTouchEnd={() => {
                if (machinetype !== 'big') handleClick(data);
              }}
              type="button"
              className={pressedKey === data ? className : ''}
              onClick={() => handleChange(data)}
              value={data}
              key={data}>
              <span className="keyboard-text">{data}</span>
            </button>
          ))}
        </div>
        <div className={machinetype !== 'big' ? 'Keyboard-keys' : 'Old-Keyboard-keys'}>
          {showEnterCancle && (
            <button
              onTouchEnd={() => {
                if (machinetype !== 'big') handleClick('*');
              }}
              type="button"
              className={pressedKey === '*' ? className : ''}
              onClick={() => handleChange('*')}>
              <span className="keyboard-text"> * </span>
            </button>
          )}

          {!showEnterCancle && (
            <button
              onTouchEnd={() => {
                if (machinetype !== 'big') handleClick('*');
              }}
              type="button"
              className={pressedKey === '*' ? className : ''}>
              <span className="keyboard-text">*</span>
            </button>
          )}
          <button
            onTouchEnd={() => {
              if (machinetype !== 'big') handleClick('0');
            }}
            type="button"
            className={pressedKey === '0' ? className : ''}
            onClick={() => handleChange('0')}
            value="0"
            key="0">
            <span className="keyboard-text">0</span>
          </button>
          <button
            onTouchEnd={() => {
              if (machinetype !== 'big') handleClick('<');
            }}
            type="button"
            className={pressedKey === '<' ? className : ''}
            onClick={() => handleChange('<')}
            value={'<'}
            key={'<'}>
            {!showEnterCancle && (
              <span className="keyboard-text">
                <img alt="<" className="keyboard-backspace" src="/assets/backspace.svg" />
              </span>
            )}
            {showEnterCancle && <span className="keyboard-text">#</span>}
          </button>
        </div>
      </div>
    </div>
  );
};
