/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { CartDataListUI, CartDataCardsUI } from '../../components';
import CancelPaymentConfirmModal from '../../components/cancelPaymentConfirmModal';
import { addMoneyToWalletV2, disablingCashModule } from '../../actions/cashActions';
import { uniqid, createRequest, updateRequestPaymentStatus, updateRequestPhoneNumber } from '../../actions/db';
// import WalletTransferConfirmModal from './walletTransferConfirmModal';
import './style.css';
import PayModalUI from '../../components/PaymenModal/PayModalUI';
import TimerHeader from '../Timer/index';

import { checkPaymentStatus, getRequestId, updateRequestStatus, cancelPaymentLink } from '../../actions/payment';
import {
  CASH_MODULE_STATUS,
  ESP_STATUS,
  PAYMENT_STATUS,
  REQUEST_REDUCER_TYPES,
  VEND_REDUCER,
  LOG,
  MODAL_REDUCER,
  CART_REDUCER,
  TIMER,
  COLORS,
  MACHINE_STATUS,
  MACHINE,
  CASH_RETURNER_STAGE,
} from '../../actions/constants';
// ERROR_CODE,

// import * as Sentry from '@sentry/browser';
import SentryLog, { sendMail } from '../../actions/logs';

class Payment extends React.Component {
  constructor(props) {
    // let upiIndex = 0;
    super(props);
    const { enabledPaymentGateways, paymentModalUIOptions } = { ...this.props };

    let defaultPaymentMethodSelected = 0;
    if (paymentModalUIOptions.defaultPaymentMethod) {
      defaultPaymentMethodSelected = enabledPaymentGateways.indexOf(paymentModalUIOptions.defaultPaymentMethod);
    } else {
      for (let i = 0; i < enabledPaymentGateways.length; i += 1) {
        if (enabledPaymentGateways[i] === 'paytm') {
          defaultPaymentMethodSelected = i;
          break;
        } else if (enabledPaymentGateways[i] === 'prasuk') {
          defaultPaymentMethodSelected = i;
          break;
        }
      }
    }

    this.state = {
      conformPaymentCancelModal: false, // Close Nested Modal If 'payment cancel confirm modal' modal is open or not
      paymentTime: paymentModalUIOptions.paymentTimeout, // Time (in seconds) after which payment modal will close
      isPaymentTimedOut: false, // If true show another modal
      isPolling: false, // Start Polling
      isFetchingRequestId: true,
      errorFetchingRequestId: false,
      isRequestIdFetched: false,
      paymentModeSelected: enabledPaymentGateways ? enabledPaymentGateways[defaultPaymentMethodSelected] : [], // Selected mode of payment
      phoneNumber: '',
      isPhoneNumberValid: false,
      isSwiftOTPSent: false,
      rfidPin: '',
      isClosePayModalValid: false,
      showWarningWhenCashReceviedAndTryToChangePaymentMode: false,
    };

    [
      'toggleConformPaymentCancelModal', // Close `confirm to cancel` Modal
      'cancelPayment', // Close payment modal
      'changePaymentMethod', // Change selected payment method
      'startPolling', // set Interval for check status polling
      'stopPolling', // unset Interval for check status polling
      'setTransactionStatus', // Set transaction status. if true-> startVending
      'startPayTimer', // start payment modal timer
      'fetchRequestId', // get RequestId
      'handlePhoneNumberChange', // handle phone number change
      'swiftWalletOTPStatusUpdate', // Update Swift OTP Flag
      'checkPaymentStatus',
      'handlePinNumberChange',
      'startVend',
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    const { setPaymentNotCanceled, resetCoinReturner } = this.props;
    setPaymentNotCanceled();
    resetCoinReturner();
    this.startPayTimer();
    this.fetchRequestId();
  }

  componentWillUnmount() {
    // Stop payment wait Timer
    const { isClosePayModalValid, paymentTime } = { ...this.state };
    const { paymentStatus, machineId } = { ...this.props };

    if (paymentTime > 0 && !isClosePayModalValid && paymentStatus !== PAYMENT_STATUS.COMPLETED) {
      const requestBody = {
        to: 'machine1002@wendor.in',
        subject: `${machineId} - Paymodal closed unexpectidly ......`,
        html_body: `State is  ${JSON.stringify(this.state)} and props is ${JSON.stringify(this.props)} `,
        alt_body: 'Tested',
      };
      sendMail(requestBody);
    }
    window.customClearSetTimeout(this.payTimer, TIMER.PAY_COMPLETE_TIMEOUT);
    window.customClearSetTimeout(this.checkPaymentStatusTimeout, TIMER.CHECK_PAYMENT_STATUS_TIMMER); // stop checking for payment status
    this.stopPolling();
  }

  getSnapshotBeforeUpdate() {
    const { paymentStatus } = { ...this.props };
    if (paymentStatus === PAYMENT_STATUS.COMPLETED) {
      this.stopPolling(); // Stop Polling
      this.startVend(); // Start Vending
    }
    return null;
  }

  setTransactionStatus(isSuccessful, method, paymentStatus) {
    const { totalAmount, updatePaymentStatus } = { ...this.props };
    // After the payment is done
    if (paymentStatus === PAYMENT_STATUS.COMPLETED && isSuccessful) {
      updatePaymentStatus({
        status: PAYMENT_STATUS.COMPLETED,
        totalAmountPaid: totalAmount,
        method,
      });

      this.startVend();
    }
  }

  startVend() {
    const { openVendModal } = { ...this.props };

    openVendModal(true); // Start Vending is transaction is successful.
  }

  toggleConformPaymentCancelModal() {
    // 'Confirm to cancel' modal
    const { conformPaymentCancelModal } = { ...this.state };

    this.setState({
      conformPaymentCancelModal: !conformPaymentCancelModal,
    });
  }

  fetchRequestId() {
    // Get RequestId for the new payment
    const {
      cartData,
      totalAmount,
      totalItems,
      resetVendingDetails,
      setRequestId,
      setMachineStatus,
      offlineEnabledMethods,
      machineId,
    } = { ...this.props };

    const details = {
      items: cartData,
      amount: totalAmount.toFixed(2),
      total_items: totalItems,
    };
    // console.log('Navigator Online:', navigator.onLine);
    if (navigator.onLine) {
      setMachineStatus({ type: MACHINE.SET_MACHINE_ONLINE });

      getRequestId({ details })
        .then(async data => {
          if (!data.error) {
            const { amount, paid_amount: paidAmount, request_id: requestId } = data;
            await setRequestId(requestId); // Request Id is in Parent component as it is being used while sending VendModal data also.
            this.setState({
              isRequestIdFetched: true,
              isFetchingRequestId: false,
              errorFetchingRequestId: false,
            });
            resetVendingDetails();
            if (paidAmount >= amount) {
              this.stopPolling(); // Stop Polling
              this.setTransactionStatus(true, 'cash', PAYMENT_STATUS.COMPLETED); // Set transaction to true and start vending
              // console.log('IS_POLLING_2');
              return;
            }
            this.startPolling();
          } else {
            this.setState({
              isRequestIdFetched: false,
              isFetchingRequestId: false,
              errorFetchingRequestId: true,
            });
            await setRequestId(null);
          }
        })
        .catch(() => {
          // console.log('Error: No RequestId', e);
          // Internet Not working
          this.setState({
            isRequestIdFetched: false,
            isFetchingRequestId: false,
            errorFetchingRequestId: true,
          });
          setRequestId(null);
        });
    } else if (!navigator.onLine && offlineEnabledMethods.length > 0) {
      // Machine is Offline
      // Set Machine offline
      this.setState({
        paymentModeSelected: offlineEnabledMethods[0],
      });
      setMachineStatus({ type: MACHINE.SET_MACHINE_OFFLINE });
      // Generate Request Id
      const prefix = `${machineId}_ll_`;
      const requestId = uniqid(prefix);
      setRequestId(requestId);
      createRequest(requestId, cartData, totalAmount); // Save to localdb
      this.setState({
        isRequestIdFetched: true,
        isFetchingRequestId: false,
        errorFetchingRequestId: false,
      });
      resetVendingDetails();
    } else {
      this.setState({
        isRequestIdFetched: false,
        isFetchingRequestId: false,
        errorFetchingRequestId: true,
      });
      setRequestId(null);
    }
    return {};
  }

  startPayTimer() {
    // start payment modal timer
    const { paymentTime } = { ...this.state };

    this.payTimer = window.customSetTimeOut(
      () => {
        const { paymentStatus, requestId, machineId } = { ...this.props };

        if (paymentStatus !== PAYMENT_STATUS.COMPLETED) {
          // If payment is not successful then cancel the payment
          this.setState({
            isPaymentTimedOut: true,
          });
          // send mail
          const requestBody = {
            to: 'machine1002@wendor.in',
            subject: `${machineId} - Paymodal timer up`,
            html_body: `order id is ${requestId} `,
            alt_body: 'Tested',
          };
          sendMail(requestBody);
          // if (paymentModeSelected === 'cash') {
          //   this.addMoneyToWallet();
          // }
          this.cancelPayment();
        } else {
          this.checkPaymentStatus();
        }
      },
      1000 * paymentTime,
      TIMER.PAY_COMPLETE_TIMEOUT,
    ); // Wait for 4 minutes to complete the transaction
  }

  async stopPolling() {
    // Stop Polling

    await this.setState({
      isPolling: false,
    });
  }

  startPolling() {
    // Start Polling
    this.setState({
      isPolling: true,
    });
  }

  checkPaymentStatus() {
    const { requestId } = { ...this.props };
    let { paymentStatus } = { ...this.props };
    const { isPolling, paymentModeSelected } = { ...this.state };
    // console.log('checkPaymentStatus', requestId, paymentModeSelected);
    if (paymentStatus !== PAYMENT_STATUS.COMPLETED) {
      if (isPolling) {
        // Check status only when payModal is open
        checkPaymentStatus(paymentModeSelected, requestId)
          .then(data => {
            ({ paymentStatus } = { ...this.props });
            window.customClearSetTimeout(this.checkPaymentStatusTimeout, TIMER.CHECK_PAYMENT_STATUS_TIMMER); // stop checking for payment status

            if (!data.error) {
              // console.log(1);
              if (data.status && data.status.toLowerCase() === 'success') {
                // console.log(2);
                // Payment successful
                this.stopPolling(); // Stop Polling
                this.setTransactionStatus(true, paymentModeSelected, PAYMENT_STATUS.COMPLETED); // Set transaction to true and start vending
              } else {
                // console.log(3);
                this.checkPaymentStatusTimeout = window.customSetTimeOut(
                  () => this.checkPaymentStatus(),
                  5000,
                  TIMER.CHECK_PAYMENT_STATUS_TIMMER,
                ); // Keep checking for payment status
              }
            } else {
              // Network problem
              // console.log(4);
              // console.log('got error in checkpay status', data);
              this.checkPaymentStatusTimeout = window.customSetTimeOut(
                () => this.checkPaymentStatus(),
                3000,
                TIMER.CHECK_PAYMENT_STATUS_TIMMER,
              ); // Keep checking for payment status
              // If there is internet problem, try after 3 seconds
            }
          })
          .catch(() => {
            // console.log('CheckPayment Status - In Catch: ', e);
            this.checkPaymentStatusTimeout = window.customSetTimeOut(
              () => this.checkPaymentStatus(),
              3000,
              TIMER.CHECK_PAYMENT_STATUS_TIMMER,
            ); // Keep checking for payment status
          });
      }
    } else if (paymentStatus === PAYMENT_STATUS.COMPLETED) {
      this.setState({
        checkingSMSLink: false,
      });
      this.stopPolling(); // Stop Polling
      this.setTransactionStatus(true, paymentModeSelected, paymentStatus); // Set transaction to true and start vending
    }
  }

  async cancelPayment() {
    // Close Payment Modal
    const {
      requestId,
      callback,
      resetCart,
      openPaymentTimedOutModal,
      closePaymentModal,
      machineDetails,
      machineId,
      setRequestId,
      machineStatus,
      totalAmountPaid,
      openPushRemainderToWallet,
      setRequestIdAfterVend,
      setPaymentCanceled,
    } = this.props;

    const { isPaymentTimedOut, phoneNumber, isPhoneNumberValid, paymentModeSelected } = { ...this.state };
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));

    this.setState({ isClosePayModalValid: true, conformPaymentCancelModal: false });

    // Reset Payment status. Set RequestId to null and reset all QR Data
    await this.stopPolling();

    // console.log('paymentModeSelected', paymentModeSelected);
    // console.log('cashPhoneOption', cashPhoneOption);
    // console.log(
    //   'cashPhoneOption',
    //   cashPhoneOption !== 'coin_returner_with_phone_after_payment' && paymentModeSelected !== 'cash',
    // );

    let cancelReason = 'cancelled'; // only 'cancelled' and 'timeout' is allowed
    if (isPaymentTimedOut) {
      cancelReason = 'timeout';
    }
    if (cashPhoneOption !== 'phone_after_payment' && machineStatus === MACHINE_STATUS.ONLINE) {
      if (
        isPhoneNumberValid &&
        paymentModeSelected === 'cash' &&
        cashPhoneOption !== 'coin_returner_with_skip' &&
        cashPhoneOption !== 'coin_returner_with_phone_after_payment'
      ) {
        // Flow is important. If cash added then first transfer money to wallet.
        const requestData = {
          order_id: requestId,
          machineId,
          phone_number: phoneNumber,
        };
        // console.log('Add Money to wallet =', requestData);
        addMoneyToWalletV2(requestData).then(() => {
          disablingCashModule({
            requestId,
            comment: 'Disable Cash Module',
          });
          updateRequestStatus(requestId, cancelReason);
        });
      } else if (cashPhoneOption !== 'coin_returner_with_phone_after_payment') {
        updateRequestStatus(requestId, cancelReason);
      } else if (cashPhoneOption === 'coin_returner_with_phone_after_payment' && paymentModeSelected !== 'cash') {
        updateRequestStatus(requestId, cancelReason);
      }
    } else if (isPhoneNumberValid && paymentModeSelected === 'offlinecash') {
      // Machine was offline. Update Request Status to cancelled.
      updateRequestPhoneNumber(requestId, phoneNumber);
      updateRequestPaymentStatus(requestId, cancelReason, totalAmountPaid, 'offlinecash');
    }

    if (
      paymentModeSelected === 'cash' &&
      (cashPhoneOption === 'coin_returner_with_phone_after_payment' ||
        cashPhoneOption === 'coin_returner_with_skip' ||
        cashPhoneOption === 'phone_after_payment') &&
      totalAmountPaid > 0
    ) {
      setRequestIdAfterVend(requestId);
      openPushRemainderToWallet();
      setPaymentCanceled();
      await setRequestId(null);
    } else {
      await setRequestId(null);
      if (machineDetails.maxItems === 1) {
        // Reset Cart
        resetCart();
      }
      if (isPaymentTimedOut) {
        await openPaymentTimedOutModal();
      } else {
        await closePaymentModal();
      }
    }
    callback();
  }

  swiftWalletOTPStatusUpdate(isSent) {
    this.setState({
      isSwiftOTPSent: isSent,
    });
  }

  handlePinNumberChange(e) {
    const { rfidPin } = { ...this.state };

    let updatedPhoneNumber = rfidPin;
    if (e === '<') {
      updatedPhoneNumber = rfidPin.slice(0, -1);
    } else if (rfidPin.length < 10) {
      updatedPhoneNumber = rfidPin + e;
      this.setState({ isPhoneNumberValid: false });
    }
    if (updatedPhoneNumber.length === 10) {
      this.setState({ isPhoneNumberValid: true });
    } else {
      this.setState({ isPhoneNumberValid: false });
    }
    this.setState({ rfidPin: updatedPhoneNumber });
  }

  handlePhoneNumberChange(e) {
    const { phoneNumber } = { ...this.state };
    let updatedPhoneNumber = phoneNumber;
    const phoneMaxLength = 10; // max length for phone number
    const phoneMinLength = 9; // min length for phone number
    if (e === '<') {
      updatedPhoneNumber = phoneNumber.slice(0, -1);
    } else if (phoneNumber.length < 10) {
      updatedPhoneNumber = phoneNumber + e;
      this.setState({ isPhoneNumberValid: false });
    }
    const phoneRegex = new RegExp(`^\\(?([0-9]{${phoneMinLength},${phoneMaxLength}})\\)?$`);
    const isValidPhoneNumber = phoneRegex.test(updatedPhoneNumber);
    if (isValidPhoneNumber) {
      this.setState({ isPhoneNumberValid: true });
    } else {
      this.setState({ isPhoneNumberValid: false });
    }
    this.setState({ phoneNumber: updatedPhoneNumber });
  }

  componentDidCatch(err, info) {
    // enter
    // console.log(err);
    // console.log(info);
    // console.log('Component got error');
    this.setState({
      errorInComponent: true,
    });
    SentryLog(err, info);
  }

  async changePaymentMethod(method) {
    // Stop polling when method changed
    const { paymentModeSelected } = { ...this.state };
    const { totalAmountPaid, requestId } = { ...this.props };
    // if initial method is link then cancel the sent link

    if (paymentModeSelected === 'link') {
      // Cancel Payment link as a new link will be sent on next link request.
      cancelPaymentLink({ requestId }).then(res => {
        if (!res.error) {
          // console.log('Previous link cancelled.');
        } else {
          // console.log(res.error);
        }
      });
    }

    if (totalAmountPaid > 0 && ['cash', 'offlinecash'].indexOf(paymentModeSelected) > -1) {
      this.setState({
        showWarningWhenCashReceviedAndTryToChangePaymentMode: true,
      });
      setTimeout(() => {
        this.setState({
          showWarningWhenCashReceviedAndTryToChangePaymentMode: false,
        });
      }, 4000);
    } else {
      await this.setState({
        paymentModeSelected: method,
      });

      switch (method) {
        case 'paytm':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'upi':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'stripe_link_qr':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'razorpay_link_qr':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'link':
          // this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'cash':
          this.stopPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'swift':
          return this.stopPolling();
        case 'free_vend':
          return this.stopPolling();
        case 'lps_bossard':
          return this.stopPolling();
        case 'prasuk':
          return this.stopPolling();
        case 'prasuk-paytm':
          return this.stopPolling();
        case 'rfid':
          this.setState({ isSwiftOTPSent: false });
          this.stopPolling();
          return null;
        case 'sodexo':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'offlinecash':
          this.stopPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'fonepay':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        case 'ezetap':
          this.startPolling();
          this.setState({ isSwiftOTPSent: false });
          break;
        default:
          return this.cancelPayment();
      }
    }
    return {};
  }

  render() {
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));
    const {
      paymentModeSelected,
      isRequestIdFetched,
      errorFetchingRequestId,
      conformPaymentCancelModal,
      isPhoneNumberValid,
      phoneNumber,
      isSwiftOTPSent,
      rfidPin,
      showWarningWhenCashReceviedAndTryToChangePaymentMode,
    } = { ...this.state };
    const {
      cartData,
      isOpenQRModal,
      totalAmount,
      totalAmountPaid,
      espStatus,
      requestId,
      enabledPaymentGateways,
      paymentModalUIOptions,
      machineDetails,
      machineStatus,
      payModalLanguage,
    } = {
      ...this.props,
    };

    const {
      txtPayModalTitle,
      txtFetchRequestIdFail,
      txtInternetConnectionError,
      txtRetry,
      btnRetry,
      btnCacnel,
      txtCash,
      txtSmartCard,
      txtCard,
      txtWendorSwift,
      txtLps,
      txtPrasuk,
      txtTotalAmount,
      txtCancelOrderConfirm,
      txtBalanceTransferToSwift,
      btnCancelConfirmNo,
      btnCancelConfirmYes,
    } = payModalLanguage;

    const payUI = () => (
      <div id="PayUI">
        {
          <PayModalUI
            paymentMethod={paymentModeSelected}
            checkPaymentStatus={this.checkPaymentStatus}
            changePaymentMethod={this.changePaymentMethod}
            toggleConformPaymentCancelModal={this.toggleConformPaymentCancelModal} // Close the 'confirm to cancel' Modal
            handlePhoneNumberChange={this.handlePhoneNumberChange}
            isPhoneNumberValid={isPhoneNumberValid}
            phoneNumber={phoneNumber}
            rfidPin={rfidPin}
            totalAmountPaid={totalAmountPaid}
            requestId={requestId}
            handlePinNumberChange={this.handlePinNumberChange}
            isSwiftOTPSent={isSwiftOTPSent}
            enabledPaymentGateways={enabledPaymentGateways}
            swiftWalletOTPStatusUpdate={this.swiftWalletOTPStatusUpdate}
            showWarningWhenCashReceviedAndTryToChangePaymentMode={showWarningWhenCashReceviedAndTryToChangePaymentMode}
            paymentModalUIOptions={paymentModalUIOptions}
            isOnline={machineStatus === MACHINE_STATUS.ONLINE}
            txtCash={txtCash}
            txtSmartCard={txtSmartCard}
            txtCard={txtCard}
            txtWendorSwift={txtWendorSwift}
            txtLps={txtLps}
            txtPrasuk={txtPrasuk}
          />
        }
      </div>
    );

    const cartDataUI = () => {
      if (!cartData.length || !paymentModalUIOptions.cartDetails.showCartData) {
        return <div />;
      }
      if (paymentModalUIOptions.cartDetails.cartViewType === 'cards') {
        return (
          <CartDataCardsUI
            cartData={cartData}
            paymentModalUIOptions={paymentModalUIOptions}
            defaultProductURL={machineDetails.defaultProductURL}
          />
        );
      }
      // Default type list
      const cartStyle = {};

      if (paymentModalUIOptions.cartDetails.cartPosition === 'top') {
        cartStyle.borderBottom = `1px solid ${COLORS.GREY}`;
        cartStyle.marginBottom = '10px';
      } else {
        cartStyle.borderTop = `1px solid ${COLORS.GREY}`;
        cartStyle.marginTop = '10px';
      }

      return (
        <div style={cartStyle} id="cartData">
          {paymentModalUIOptions.showOrderId && (
            <Row className="payment-cart-title">
              <Col xs={8} sm={8} md={8} lg={8}>
                <span className="bold">Order Id: {requestId}</span>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4}>
                <span className="bold pull-right">
                  {txtTotalAmount}{' '}
                  <NumberFormat
                    value={` ${totalAmount} `}
                    displayType="text"
                    thousandSeparator
                    prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                    suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                  />
                </span>
              </Col>
            </Row>
          )}
          <CartDataListUI cartData={cartData} />
        </div>
      );
    };

    const renderUI = () => {
      if (paymentModalUIOptions.cartDetails.cartPosition === 'top') {
        return (
          <>
            {cartDataUI()}
            {payUI()}
          </>
        );
      }
      // Default Bottom
      return (
        <>
          {payUI()}
          {cartDataUI()}
        </>
      );
    };

    return (
      <Modal
        className="main-modal"
        isOpen={isOpenQRModal}
        toggle={() => this.toggleConformPaymentCancelModal()}
        size={machineDetails.modalSize || 'md'}>
        <ModalHeader toggle={this.toggleConformPaymentCancelModal}>
          <span style={{ flex: 2 }}>
            {txtPayModalTitle} <TimerHeader maxTime={paymentModalUIOptions.paymentTimeout} />
          </span>
        </ModalHeader>
        <div className="loader-linear" />
        <div className="modal-body">
          <CancelPaymentConfirmModal
            isOpen={conformPaymentCancelModal}
            close={this.toggleConformPaymentCancelModal}
            cancelPayment={this.cancelPayment}
            totalAmountPaid={totalAmountPaid}
            paymentModeSelected={paymentModeSelected}
            phoneNumber={phoneNumber}
            modalSize={machineDetails.modalSize}
            txtCancelOrderConfirm={txtCancelOrderConfirm}
            txtBalanceTransferToSwift={txtBalanceTransferToSwift}
            btnCancelConfirmNo={btnCancelConfirmNo}
            btnCancelConfirmYes={btnCancelConfirmYes}
          />
          {paymentModalUIOptions.playSound && (
            <audio autoPlay>
              <source src="/audio/payement-success-2.ogg" type="audio/ogg" />
              <source src="/audio/payement-success-2.wav" type="audio/wav" />
              <source src="/audio/payement-success-2.mp3" type="audio/mpeg" />
              <track src="" kind="captions" srcLang="en" label="english_captions" />
            </audio>
          )}
          {/* {isFetchingRequestId && <div className="loader-linear" />} */}
          {errorFetchingRequestId && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <p className="0 ">{txtFetchRequestIdFail}</p>
                </Row>
                <Row>
                  <Button color=" wendor-small-button wendor-bg-red" onClick={() => this.cancelPayment()}>
                    {btnCacnel}
                  </Button>
                  <Button color=" wendor-small-button wendor-bg-green" onClick={() => this.fetchRequestId()}>
                    {btnRetry}
                  </Button>
                </Row>
                <Row>
                  <p>{txtInternetConnectionError}</p>
                </Row>
              </Col>
            </Row>
          )}
          {isRequestIdFetched &&
            (espStatus !== ESP_STATUS.DISCONNECTED || machineStatus === MACHINE_STATUS.OFFLINE) &&
            renderUI()}
          {isRequestIdFetched && espStatus !== ESP_STATUS.CONNECTED && machineStatus === MACHINE_STATUS.ONLINE && (
            <div>
              <Row>
                <p>{txtRetry}</p>
              </Row>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ machine, modalReducer, cartReducer, requestReducer, languageReducer }) => ({
  machineId: machine.machineId,
  machineStatus: machine.machineStatus,
  enabledPaymentGateways: machine.enabledPaymentGateways || [],
  offlineEnabledMethods: machine.offlineEnabledMethods || [],
  machineDetails: machine.machineDetails,
  paymentModalUIOptions: machine.paymentModal,
  isOpenQRModal: modalReducer.isOpenQRModal,
  cartData: cartReducer.cartData,
  totalAmount: cartReducer.totalAmount,
  totalItems: cartReducer.totalItems,
  requestId: requestReducer.requestId,
  paymentStatus: requestReducer.paymentStatus,
  totalAmountPaid: requestReducer.totalAmountPaid,
  espStatus: machine.espStatus,
  payModalLanguage: languageReducer.payModalLanguage,
});

const mapDispatchToProps = dispatch => ({
  setRequestIdAfterVend: payload => dispatch({ type: REQUEST_REDUCER_TYPES.SET_REQUEST_ID_AFTER_VEND, payload }),
  openPaymentTimedOutModal: () => dispatch({ type: MODAL_REDUCER.OPEN_PAYMENT_TIMEDOUT_MODAL, payload: true }),
  closePaymentModal: () => dispatch({ type: MODAL_REDUCER.OPEN_QR_MODAL, payload: false }),
  resetCart: () => dispatch({ type: CART_REDUCER.RESET_CART }),
  resetVendingDetails: () => dispatch({ type: VEND_REDUCER.RESET }),
  setRequestId: payload => dispatch({ type: REQUEST_REDUCER_TYPES.SET_REQUEST_ID, payload }),
  setMachineStatus: payload => dispatch({ type: payload.type }),
  openVendModal: payload => dispatch({ type: MODAL_REDUCER.OPEN_VEND_MODAL, payload }),
  updatePaymentStatus: payload => dispatch({ type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT, payload }),
  addClickToLog: payload => dispatch({ type: LOG.ADD_CLICK_TO_LOG, payload }),
  addPhonenumber: payload => dispatch({ type: CASH_MODULE_STATUS.SAVE_PHONE_NUMBER, payload }),
  openPushRemainderToWallet: () => dispatch({ type: MODAL_REDUCER.OPEN_PUSH_REMAINDER_TO_WALLET_MODAL, payload: true }),
  setPaymentCanceled: () => dispatch({ type: REQUEST_REDUCER_TYPES.SET_IS_PAYMENT_CANCEL, payload: true }),
  setPaymentNotCanceled: () => dispatch({ type: REQUEST_REDUCER_TYPES.SET_IS_PAYMENT_CANCEL, payload: false }),
  resetCoinReturner: () => dispatch({ type: CASH_RETURNER_STAGE.RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
