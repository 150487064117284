import React from 'react';
import { Row, Col } from 'reactstrap';
import { FETCH_SODEXO_QR_STATES } from '../../../actions/constants';
import { getPaymentDetails } from '../../../actions/payment';
import ProcessingPaymentSedxo from '../../../components/PaymentModes/sodexo/ProcessingPaymentSedxo';
import QRDisplay from '../../../components/QrDisplay';

class Sodexo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingQRDataState: FETCH_SODEXO_QR_STATES.IDLE,
      QRData: '',
      viewState: 1, // 1-> Entering Phone number. 2-> Showing QR
    };
  }

  componentDidMount() {
    this.getSodexoDetails();
  }

  goBack = () => {
    // Change entered number
    this.setState({
      viewState: 1,
      fetchingQRDataState: 'IDLE',
    });
  };

  getSodexoDetails = () => {
    const { checkPaymentStatus, requestId, phoneNumber, isPhoneNumberValid } = { ...this.props };
    if (isPhoneNumberValid) {
      const requestData = {
        phone: phoneNumber,
        payment_method: 'sodexo',
        request_id: requestId,
      };
      getPaymentDetails(requestData)
        .then(data => {
          if (!data.error) {
            this.setState({
              QRData: data.data,
              viewState: 2,
              fetchingQRDataState: FETCH_SODEXO_QR_STATES.SUCCESS,
            });
            checkPaymentStatus();
          } else {
            this.setState({
              QRData: '',
              fetchingQRDataState: FETCH_SODEXO_QR_STATES.FAILURE,
            });
          }
        })
        .catch(() => {
          this.setState({
            QRData: '',
            fetchingQRDataState: FETCH_SODEXO_QR_STATES.FAILURE,
          });
        });
    }
  };

  render() {
    const { viewState, fetchingQRDataState, QRData } = { ...this.state };
    const { handlePhoneNumberChange, phoneNumber, isPhoneNumberValid } = this.props;

    if (viewState === 1) {
      return (
        <ProcessingPaymentSedxo
          fetchingQRDataState={fetchingQRDataState}
          getSodexoDetails={this.getSodexoDetails}
          isPhoneNumberValid={isPhoneNumberValid}
          handlePhoneNumberChange={handlePhoneNumberChange}
          phoneNumber={phoneNumber}
        />
      );
    }
    if (viewState === 2) {
      return (
        <QRDisplay
          QRData={QRData}
          isQRFetched
          isQRFetchError={false}
          mainMsg={`Entered Phone Number: ${phoneNumber}.`}
          showButton
          buttonTxt="Change number?"
          buttonClickFunction={this.goBack}
        />
      );
    }
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h1>Something bad happend</h1>
        </Col>
      </Row>
    );
  }
}

export default Sodexo;
