import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { unassignMachine } from '../actions/auth';
import ButtonMediumRed from '../components/buttonMediumRed';

const LogoutButton = ({ dataTestid }) => {
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  const onButtonClick = async () => {
    try {
      setisLoading(true);
      const { error } = await unassignMachine();
      setisLoading(false);

      if (!error) {
        localStorage.clear();
        history.push('/login');
        setisError(false);
      } else {
        setisError(true);
      }
    } catch (e) {
      setisLoading(false);
      setisError(true);
    }
  };

  let displayMsg = 'Machine Logout';

  displayMsg = isLoading ? 'Loading...' : displayMsg;
  displayMsg = isError ? 'Retry' : displayMsg;

  return <ButtonMediumRed dataTestid={dataTestid} buttonAction={onButtonClick} buttonText={displayMsg} />;
};

export default LogoutButton;
