/*
  Processing Sedxo payment
  - Fetching details 
  - Failure in fetching
  - Getting user phonenumber when idle 
*/

import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import { FETCH_SODEXO_QR_STATES } from '../../../actions/constants';
import GetUserPhoneNumber from '../../GetUserPhoneNumber';

const ProcessingPaymentSedxo = ({
  fetchingQRDataState,
  getSodexoDetails,
  phoneNumber,
  isPhoneNumberValid,
  handlePhoneNumberChange,
}) => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        {fetchingQRDataState === FETCH_SODEXO_QR_STATES.FETCHING && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h1>Please wait ...</h1>
              <div className="loader-linear" />
            </Col>
          </Row>
        )}
        {fetchingQRDataState === FETCH_SODEXO_QR_STATES.FAILURE && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>Failed fetching QR for Sodexo.</p>
              <Button color="green" onClick={() => getSodexoDetails()}>
                Retry
              </Button>
            </Col>
          </Row>
        )}
        {fetchingQRDataState === FETCH_SODEXO_QR_STATES.IDLE && (
          <GetUserPhoneNumber
            phoneNumber={phoneNumber}
            isPhoneNumberValid={isPhoneNumberValid}
            handlePhoneNumberChange={handlePhoneNumberChange}
            mainHeading="Enter your phone number"
            subHeading="For support, cashbacks and offers."
            buttonText="Submit"
            onButtonClick={getSodexoDetails}
          />
        )}
      </Col>
    </Row>
  );
};

export default ProcessingPaymentSedxo;
