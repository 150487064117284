import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { validate } from '../actions/auth';
import { LoaderInLine } from '../components';
import { refillStateCheck } from '../actions/refill';
import { updateMachineStatus, setMachinePaymentMethods } from '../actions/machine';
import { MACHINE, MACHINE_TYPE_SETTINGS } from '../actions/constants';
// import LogoutButton from '../containers/logout';

const updateMachineConfiguration = (machineConfiguration, machineSettings) => {
  const defaultMachineSettings = Object.assign({}, machineSettings);

  const {
    kiosk_vend_init_timeout: kioskVendInitTimeout,
    kiosk_vend_item_timeout: kioskVendItemTimeout,
    kiosk_allow_order_before_collection: allowOrderBeforeCollection,
    kiosk_payment_timeout: paymentTimeout,
    kiosk_screensaver_timeout: screensaverTimeout,
    screensaver_image: screensaverImage,
    kiosk_cart_timeout: cartTimeout,
    kiosk_feedback_timeout: feedbackTimeout,
    kiosk_vmc_ping_timeout: vmcPingTimeout,
    kiosk_vmc_ping_interval: vmcPingInterval,
    kiosk_display_default_product_url: defaultProductURL,
    kiosk_machine_error_image_url: errorImageURL,
    kiosk_refill_login_image_url: refillLoginImageURL,
    kiosk_refill_show_test_row: showTestRowButton,
    show_screensaver: showScreensaver,

    kiosk_refill_show_home_motor: showHomeMotorButton,
    kiosk_refill_show_open_settings: showOpenSettingsButton,
    kiosk_display_head_image_url: headImageURL,
    kiosk_display_show_footer: showFooter,
    kiosk_get_feedback: getFeedback,
    kiosk_vend_show_item_details: showVendingItemDetails,
    kiosk_vend_show_card_details: showVendingCardDetails,
    kiosk_default_payment_method: defaultPaymentMethod,
    kiosk_rfid_show_user_details: showRfidUserDetails,
  } = { ...machineConfiguration };

  defaultMachineSettings.refillPage.showOpenSettingsButton = showOpenSettingsButton;
  defaultMachineSettings.refillPage.showTestRowButton = showTestRowButton;
  defaultMachineSettings.refillPage.showHomeMotorButton = showHomeMotorButton;
  defaultMachineSettings.vendModal.allowOrderBeforeCollection = allowOrderBeforeCollection;
  defaultMachineSettings.vendModal.getFeedback = getFeedback;
  defaultMachineSettings.vendModal.showVendingItemDetails = showVendingItemDetails;
  defaultMachineSettings.vendModal.showVendingCardDetails = showVendingCardDetails;
  defaultMachineSettings.productDisplayPage.showFooterSubText = showFooter;
  defaultMachineSettings.productDisplayPage.showScreensaver = showScreensaver;

  if (kioskVendInitTimeout && !Number.isNaN(kioskVendInitTimeout)) {
    defaultMachineSettings.vendModal.vendInitMaxTime = kioskVendInitTimeout;
  }

  if (kioskVendItemTimeout && !Number.isNaN(kioskVendItemTimeout)) {
    defaultMachineSettings.vendModal.vendItemMaxTime = kioskVendItemTimeout;
  }

  if (paymentTimeout && !Number.isNaN(paymentTimeout)) {
    defaultMachineSettings.paymentModal.paymentTimeout = paymentTimeout;
  }
  if (screensaverImage) {
    defaultMachineSettings.productDisplayPage.screensaverURL = screensaverImage;
  }
  if (screensaverTimeout && !Number.isNaN(screensaverTimeout)) {
    defaultMachineSettings.productDisplayPage.screensaverTimeout = screensaverTimeout * 1000;
  }
  if (cartTimeout && !Number.isNaN(cartTimeout)) {
    defaultMachineSettings.productDisplayPage.cartTimeout = cartTimeout * 1000;
  }

  if (feedbackTimeout && !Number.isNaN(feedbackTimeout)) {
    defaultMachineSettings.machineDetails.feedbackModalTimeout = feedbackTimeout;
  }
  if (vmcPingTimeout && !Number.isNaN(vmcPingTimeout)) {
    defaultMachineSettings.machineDetails.vmcPingTimeout = vmcPingTimeout;
  }

  if (vmcPingInterval && !Number.isNaN(vmcPingInterval)) {
    defaultMachineSettings.machineDetails.vmcPingInterval = vmcPingInterval;
  }

  if (defaultProductURL) {
    defaultMachineSettings.machineDetails.defaultProductURL = defaultProductURL;
  }

  if (errorImageURL) {
    defaultMachineSettings.productDisplayPage.errorImageURL = errorImageURL;
  }

  if (refillLoginImageURL) {
    defaultMachineSettings.refillPage.refillLoginImageURL = refillLoginImageURL;
  }

  if (headImageURL) {
    defaultMachineSettings.productDisplayPage.topHeadImageURL = headImageURL;
  }

  if (defaultPaymentMethod) {
    defaultMachineSettings.paymentModal.defaultPaymentMethod = defaultPaymentMethod;
  }

  if (!showRfidUserDetails) {
    defaultMachineSettings.rfidModule.showRfidUserDetails = false;
  }
  return defaultMachineSettings;
};

const withAuth = WrappedComponent => {
  const AuthHOC = class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isResfreshing: false,
        isValidateError: false,
      };
    }

    componentDidMount() {
      const { history, machineId, loggedIn, setLoggedOut, previousEnabledPaymentGateways } = { ...this.props };
      const token = localStorage.getItem('token');
      if ((!token || !machineId) && history.location.pathname !== '/login') {
        setLoggedOut();
        return history.push('/login');
      }
      if (loggedIn && previousEnabledPaymentGateways && previousEnabledPaymentGateways.length > 0) {
        return {};
      }
      window.localStorage.removeItem('multivac');
      window.localStorage.removeItem('refillerToken');
      this.setState({
        isValidateError: false,
      });
      validate(token)
        .then(newData => {
          // console.log('VALIDATING');

          this.setDetails(newData);
          if (newData.error) {
            // window.localStorage.clear();
            // window.location.reload();
            // console.log('VALIDATION ERROR');
            this.setState({
              isValidateError: true,
            });
          } else {
            this.setState({
              isValidateError: false,
            });
          }
          if (newData.status) {
            // window.localStorage.clear();
            // window.location.reload();
          }
        })
        .catch(() => {
          // console.log('inside err');
          this.setState({
            isValidateError: true,
          });
          // window.localStorage.clear();
          // window.location.reload();
        });
      return {};
    }

    async setDetails(newData) {
      // console.log('setDetails', newData);
      const {
        history,
        setLoggedIn,
        token,
        setMachineTypeDidMatch,
        setMachineTypeDidNotMatch,
        setMachinePaymentMethodsAction,
      } = this.props;

      if (!newData.data.machine_type || !newData.data.machine_id || !newData.token) {
        return;
      }
      const {
        token: newToken,
        maxItems,
        data,
        configuration: machineConfiguration,
        currency_details: currencyDetails,
      } = { ...newData };
      // rajan change this before push
      const {
        machine_type: type,
        machine_toggle: machineToggle,
        multivac_version: multivacVersion,
        machine_id: updatedMachineId,
        broker,
        rfid_version: rfidVersion,
        coin_changer_version: coinChangerVersion,
        socket_url: socketURL,
        cash_phone_option: cashPhoneOption, // carry,phone_before_payment
      } = {
        ...data,
      };
      const {
        currency_alpha_code: currencyAlpha,
        currency_id: currencyId,
        currency_name: currencyName,
        currency_numeric_code: currencyNumCode,
        is_suffix: isSuffix,
        symbol,
      } = {
        ...currencyDetails,
      };
      const {
        broker_username: brokerUsername,
        broker_host_url: brokerURL,
        broker_port: brokerPort,
        broker_password: brokerPassword,
        broker_use_ssl: brokerUseSSL,
      } = { ...broker };

      let machineType = type.toLowerCase();
      // console.log('machineType=1', machineType);
      if (
        machineType !== 'big' &&
        machineType !== 'small' &&
        machineType !== 'imshow' &&
        machineType !== 'rowproductselect' &&
        machineType !== 'selectionbyproductid' &&
        machineType !== 'newuiwithoutphoto' &&
        machineType !== 'newuiwithphoto' &&
        machineType !== 'newuiwithphotodubai' &&
        machineType !== 'newuiwithoutphotov2' &&
        machineType !== 'newuiwithoutphotowithoutscroll'
      ) {
        // console.log('machineType=', machineType);
        await window.localStorage.setItem('machineType', 'big');
        machineType = 'big';
        setMachineTypeDidNotMatch();
      } else {
        setMachineTypeDidMatch();
      }

      let defaultMachineSettings = MACHINE_TYPE_SETTINGS[machineType];
      if (machineConfiguration) {
        defaultMachineSettings = updateMachineConfiguration(machineConfiguration, defaultMachineSettings);
      }

      if (window.heap) {
        window.heap.identify(updatedMachineId);
      }

      const peripheralVersions = {
        rfidVersion,
        coinChangerVersion,
      };

      const cash = {
        cashPhoneOption,
        currencyAlpha,
        currencyId,
        currencyName,
        currencyNumCode,
        isSuffix,
        symbol,
      };

      const multivac = {
        version: multivacVersion,
        host: brokerURL || process.env.REACT_APP_MQTT_HOST,
        username: brokerUsername || process.env.REACT_APP_MQTT_USERNAME,
        password: brokerPassword || process.env.REACT_APP_MQTT_PASSWORD,
        clientId: `wendor-kiosk-${updatedMachineId}-${Date.now()}`,
        port: parseInt(brokerPort, 10) || 8083,
        useSSL: brokerUseSSL,
      };

      window.localStorage.setItem('cash', JSON.stringify(cash));
      window.localStorage.setItem('token', newToken);
      window.localStorage.setItem('machineId', updatedMachineId);
      window.localStorage.setItem('machineType', machineType);
      const enabledPaymentGateways = newData.enabled_payment_gateways;
      window.localStorage.setItem('multivac', JSON.stringify(multivac));
      window.localStorage.setItem('peripheralVersions', JSON.stringify(peripheralVersions));
      window.localStorage.setItem('maxItems', maxItems);
      setMachinePaymentMethodsAction(enabledPaymentGateways);

      defaultMachineSettings.machineDetails.maxItems = maxItems;
      defaultMachineSettings.token = newToken;
      defaultMachineSettings.enabledPaymentGateways = enabledPaymentGateways;
      defaultMachineSettings.machineId = updatedMachineId;
      defaultMachineSettings.multivacDetails = multivac;
      defaultMachineSettings.machineType = machineType;
      defaultMachineSettings.machineToggle = machineToggle;
      // defaultMachineSettings.machineToggle = true;

      defaultMachineSettings.socketURL = socketURL || process.env.REACT_APP_SOCKET_URL;

      const offlinePaymentMethods = ['offlinecash'];
      const enabledOfflineMethods = offlinePaymentMethods.filter(x => enabledPaymentGateways.includes(x));

      defaultMachineSettings.canUseOffline = enabledOfflineMethods.length > 0;
      defaultMachineSettings.offlineEnabledMethods = enabledOfflineMethods;

      // console.log('Machine Settings: ', defaultMachineSettings);
      await setLoggedIn({ machineSettings: defaultMachineSettings });
      refillStateCheck(newToken)
        .then(res => {
          if (!res.error && res.success) {
            updateMachineStatus({ statusCode: 42, message: 'Opened for refill', mode: 'interaction', token });
            if (history.location.pathname !== '/refill') {
              history.index = -1;
              return history.push('/refill');
            }
          } else if (history.location.pathname !== '/') {
            // history.index = -1;
            // return history.push('/login');
          }
          return {};
        })
        .catch(() => {
          if (history.location.pathname !== '/') {
            // history.index = -1;
            // return history.push('/refill');
          }
          return {};
        });
    }

    render() {
      // console.log(this.state);
      const { isRefreshing, isValidateError } = { ...this.state };
      const { loggedIn, token, machineId, paymentGateways } = {
        ...this.props,
      };
      if (loggedIn && paymentGateways.length > 0 && !!machineId && !!token) {
        return <WrappedComponent {...this.props} />;
      }
      return (
        <div className="start-loading-text">
          <h5>{isRefreshing ? 'Connection is weak please check your internet connection.' : ''}</h5>
          <div className="absolute-bottom-text">
            <span>v{process.env.REACT_APP_VERSION} | </span>
            <span> Machine ID: {window.localStorage.getItem('machineId') || 'NA'}</span>
          </div>
          {!isValidateError && (
            <div>
              <h4 className="display-flex content-center">
                {isRefreshing ? 'Please click on refresh button' : ''}
                {''}Syncing System Configuration...{''}
              </h4>
              <Row>
                <LoaderInLine />
              </Row>
            </div>
          )}
          {isValidateError && (
            <div>
              <h4 className="display-flex content-center">Internet is too slow.</h4>
              <span>Please check you connection and then Refresh</span>
            </div>
          )}
          <Row>
            <Col xs={12}>
              <Button
                onClick={() => {
                  this.setState({ isRefreshing: true });
                  window.location.reload();
                }}
                disabled={isRefreshing}
                className="wendor-small-button wendor-bg-grey">
                Refresh
              </Button>
            </Col>
            {/* <Col xs={6} sm={6} md={6} lg={6} className="content-center">
              <LogoutButton />
            </Col> */}
          </Row>
        </div>
      );
    }
  };

  const mapState = ({ machine }) => ({
    token: machine.token,
    machineId: machine.machineId,
    loggedIn: machine.loggedIn,
    paymentGateways: machine.enabledPaymentGateways,
  });

  const mapDispatch = dispatch => ({
    setLoggedIn: payload => dispatch({ type: MACHINE.SET_LOGGED_IN, payload }),
    setLoggedOut: () => dispatch({ type: MACHINE.SET_LOGGED_OUT }),
    setMachineTypeDidNotMatch: () => dispatch({ type: MACHINE.MACHINE_TYPE_DID_NOT_MATCH }),
    setMachineTypeDidMatch: () => dispatch({ type: MACHINE.MACHINE_TYPE_DID_MATCH }),
    setMachinePaymentMethodsAction: data => dispatch(setMachinePaymentMethods(data)),
  });

  return connect(mapState, mapDispatch)(memo(AuthHOC));
};

export default withAuth;
